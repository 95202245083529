<template>
  <div class="el_cooperation">

    <div class="mrow hzqy"
         style="min-height:500px">
      <div class="wrapper">
        <div class="mtit1">
          <h2 class="cn">合作企业</h2>
          <div class="en">joint venture</div>
          <div class="line"></div>
        </div>
        <div class="rzqy1-tabbd">
          <ul>
            <li v-for="(item,index) in list"
                :key="index">
              <a>
                <div class="img">
                  <img :src="item.logo" />
                </div>
                <div class="txt"
                     v-html="item.companyProfile">
                  <!-- {{item.companyProfile}} -->
                </div>
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: 'el_cooperation',
      list: [
      ]
    }
  },
  mounted () {
    this.getList();
  },
  methods: {
    getList () {
      this.api.cooperativeList({ pageNo: 1, pageSize: 50 }).then(res => {
        this.list = res.data.result.records;
      })
    },
  }
}
</script>
<style scoped lang="scss">
</style>
